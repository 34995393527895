import {combineReducers} from 'redux';
import * as loginModule from './login';
import login from './login';

export const moduleList = {
  loginModule
};

export default combineReducers({
  login
});
