import axios from "axios";
import React, { Component } from "react";
import { toast } from "react-hot-toast";
import {
  Animated,
  Dimensions,
  Easing,
  InteractionManager,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useSafeArea } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Button from "../components/Button";

import Hand from "../components/HandCTA";
import Footer from "../components/Home/Footer";
import GameContext from "../context/GameContext";
import { coinContract, gameCoinContract } from "../klaytn/contracts";
import { changeMuted } from "../src/AudioManager";
import Images from "../src/Images";
import { keyMap } from '../components/GestureView';

const imageStyle = { width: 60, height: 48 };

function MobileKeyboard(props) {
  const { top, bottom, left, right } = useSafeArea();
  const move = React.useCallback((direction)=>{
    props.onResponderGrant();
    props.onSwipe(keyMap[direction])
  },[])

  return (
    <View
      style={[
        styles.container,
        {
          paddingTop: top,
          paddingBottom: bottom,
          paddingLeft: left,
          paddingRight: right,
        },
      ]}
    >
        <View
          style={{
            justifyContent: "center",
            alignItems: "stretch",
            position: "absolute",
            bottom: Math.max(bottom, 8),
            left: Math.max(left, 8),
            right: Math.max(right, 8),
          }}
        >
          <View style={{flex:1,flexDirection:'column'}}>
          <View style={{justifyContent:"center",alignSelf:'center',marginBottom:10}}>
              <Button
              onPress={()=>{
                move("ArrowUp")
              }}
              style={imageStyle}
              imageStyle={imageStyle}
              source={Images.button.up}
            />
          </View>
          <View style={{flexDirection:'row',justifyContent:"center",alignSelf:'center'}}>
          <Button
             onPress={()=>{
              move("ArrowLeft")
            }}
            style={[imageStyle,{marginRight:10}]}
            imageStyle={imageStyle}
            source={Images.button.left}
          />
          <Button
            onPress={()=>{
              move("ArrowDown")
            }}
            style={[imageStyle,{marginRight:10}]}
            imageStyle={imageStyle}
            source={Images.button.down}
          />
        
          <Button
            onPress={()=>{
              move("ArrowRight")
            }}
            style={imageStyle}
            imageStyle={imageStyle}
            source={Images.button.right}
          />
          </View>
          </View>
        </View>
    </View>
  );
}

export default MobileKeyboard;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  title: {
    // color: 'white',
    // fontSize: 48,
    // backgroundColor: 'transparent',
    // textAlign: 'center',
    resizeMode: "contain",
    maxWidth: 600,
    width: "80%",
    height: 300,
  },
  coins: {
    fontFamily: "retro",
    position: "absolute",
    right: 8,
    color: "#f8e84d",
    fontSize: 36,
    letterSpacing: 0.9,
    backgroundColor: "transparent",
    textAlign: "right",
    shadowColor: "black",
    shadowOpacity: 1,
    shadowRadius: 0,
    shadowOffset: { width: 0, height: 0 },
  },
  paragraph: {
    margin: 24,
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    color: "#34495e",
  },
});
