import {createStore, applyMiddleware} from 'redux';
// import loger from 'redux-logger';
import ReduxThunk from 'redux-thunk';
import modules from './modules';

const configure = () => {
  const store = createStore(modules, applyMiddleware(ReduxThunk));
  return store;
};

// 스토어 생성 Middleware = 액션 -> 미들웨어 -> 리듀서 순으로 중간 작업가능 ex)로그,권한확인 등
// 미들웨어가 없을경우 액션이 오면 다른 값과 상관없이 항상 같은 기능을 함
// Redux Thunk = 비동기 작업용 미들웨어 라이브러리
export default configure;
