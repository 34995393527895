import {createAction, handleActions} from 'redux-actions';

const initialState = {
  loginjwt: null,
  account:null,
};

const CHANGE_JWT = 'ACCOUNT/CHANGE_JWT';
const CHANGE_ACCOUNT = 'ACCOUNT/CHANGE_ACCOUNT';

export const changeJwt = createAction(CHANGE_JWT);
export const changeAccount = createAction(CHANGE_ACCOUNT);

const reducer = handleActions(
  {
    [CHANGE_JWT]: (state, action) => {
      const jwt = action.payload;
      return {
        ...state,
        loginjwt:jwt,
      };
    },
    [CHANGE_ACCOUNT]: (state, action) => {
      const account = action.payload;
      return {
        ...state,
        account,
      };
    },
  },
  initialState,
);

export default reducer;
