import React, { Component } from "react";
import { LayoutAnimation, Animated, StyleSheet, View, Platform } from "react-native";
import Swal from "sweetalert2";
import { MUTED } from "../../src/AudioManager";

import Images from "../../src/Images";
import Button from "../Button";
import CharacterPicker from "../CharacterPicker";

const imageStyle = { width: 60, height: 48 };

export default function Footer(props) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [update,SetUpdate] = React.useState(new Date());

  const collapse = React.useCallback(
    (onPress) => () => {
      setMenuOpen(false);
      onPress();
    },
    [setMenuOpen]
  );

  const renderMenu = React.useMemo(() => {
    return (
      <View style={{ flexDirection: "column" }}>
        <Button
          onPress={collapse(props.onMultiplayer)}
          style={[{ marginBottom: 8 }, imageStyle]}
          imageStyle={imageStyle}
          source={Images.button.controller}
        />
        <Button
          onPress={collapse(props.onShop)}
          style={[{ marginBottom: 8 }, imageStyle]}
          imageStyle={imageStyle}
          source={Images.button.shop}
        />
        <Button
          onPress={collapse(props.onCamera)}
          style={[{ marginBottom: 8 }, imageStyle]}
          imageStyle={imageStyle}
          source={Images.button.camera}
        />
      </View>
    );
  }, [collapse]);
  return (
    <Animated.View style={[styles.container, props.style]}>
      <View style={{flexDirection:'row'}}>
      <Button
        style={{ maxHeight: 48 }}
        onPress={props.onCharacterSelect}
        imageStyle={[imageStyle,{marginRight:15}]}
        source={Images.button.character}
      />
      <Button
          onPress={props.withdrawCoin}
          style={imageStyle}
          imageStyle={imageStyle}
          source={Images.button.menu}
      />
       <Button
          onPress={()=>{
            window.open('https://swapscanner.io/ko/swap?from=0x0000000000000000000000000000000000000000&to=0xe91ffe2e15ccd56b1b8ddf7cdf848dfee6b5a858');
          }}
          style={imageStyle}
          imageStyle={imageStyle}
          source={Images.button.enrgShop}
      />
      </View>

      <View style={{ flex: 1 }} />

      <View style={{ flexDirection: "row" }}>
      <Button
          onPress={()=>{
            props.showWinner()
          }}
          style={[imageStyle,{marginRight:10}]}
          imageStyle={imageStyle}
          source={Images.button.trophy}
      />
      <Button
          onPress={()=>{
            props.showRanking()
          }}
          style={[imageStyle,{marginRight:10}]}
          imageStyle={imageStyle}
          source={Images.button.rank}
      />
      <Button
          onPress={()=>{
            props.onShop()
            SetUpdate(new Date);
          }}
          style={imageStyle}
          imageStyle={imageStyle}
          source={MUTED ? Images.button.mute2 : Images.button.mute}
      />
      </View>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "flex-end",
    justifyContent: "center",
    flexDirection: "row",
    // maxHeight: 48,
  },
  paragraph: {
    margin: 24,
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    color: "#34495e",
  },
  button: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
