export default {
  Game: {
    playing: 'playing',
    paused: 'paused',
    gameOver: 'gameOver',
    none: 'none',
    ranking:'ranking',
    winner:'winner',
  },
};
