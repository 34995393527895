export default {
  // chicken: {
  //   name: 'Chicken',
  //   id: 'chicken',
  // },
  bacon: {
    name: "Bacon",
    id: "bacon"
  }
};
