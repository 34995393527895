import axios from "axios";
import EXAppLoading from "expo-app-loading";
import { useFonts } from "expo-font";
import React, { useEffect, useState } from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Provider, useDispatch, useSelector } from "react-redux";
import caver from "./klaytn/caver";

import GameProvider from "./context/GameProvider";
import GameScreen from "./screens/GameScreen";
import AudioManager from "./src/AudioManager";
import { useResolvedValue } from "./src/hooks/useResolvedValue";
import ModelLoader from "./src/ModelLoader";
import store from "./src/store";
import { changeAccount, changeJwt } from "./src/store/modules/login";
import Swal from "sweetalert2";
import { Toaster } from "react-hot-toast";

function getRandomString(length) {
  var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var result = '';
  for ( var i = 0; i < length; i++ ) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }
  return result;
}

console.ignoredYellowBox = [
  "WebGL",
  "THREE.WebGLRenderer",
  "THREE.WebGLProgram",
];

export default function App() {
  return (
    <Provider store={store}>
      <AssetLoading>
        <SafeAreaProvider>
          <GameProvider>
            <GameScreen />
          </GameProvider>
        </SafeAreaProvider>
      </AssetLoading>
      <Toaster/>
    </Provider>
  );
}

function AssetLoading({ children }) {
  const account = useSelector(state => state.login.account);
  const dispatch = useDispatch();
  const loginJwt = useSelector(state => state.login.loginjwt);

  useEffect(()=>{
    connectWallet();
  },[]);
  useEffect(()=>{
    if(account && !loginJwt){
      const nonce = getRandomString(6);
      caver.klay.sign('Natus Login'+"\n"+nonce,account).then(async(res)=>{
        const params = {
          nonce:nonce,
          text:'Natus Login'+"\n"+nonce,
          account,
          sig:res,
          account,
        }
        const respon = await axios.post('https://natusalienclub.com/login/',params);
        dispatch(changeJwt(respon.data.access_token));
      });
    }
  },[account,loginJwt]);
  async function connectWallet(){
    const { klaytn } = window
    if (klaytn) {
      try {
        await klaytn.enable();
        if (klaytn === undefined) return
        const account = klaytn.selectedAddress
        dispatch(changeAccount(account));
        klaytn.on('accountsChanged', (accounts) => {
          dispatch(changeAccount(accounts[0]));
          dispatch(changeJwt(null));
        });

        // klaytn.on('networkChanged', (res) => {
        //   console.log(res);
        //   Swal.fire('메인넷으로 변경해주세요.');
        //   dispatch(changeJwt(null));
        // });
      } catch (error) {
        console.log(error);
        console.log('User denied account access')
      }
    } else {
      console.log('Non-Kaikas browser detected. You should consider trying Kaikas!')
    }
  }

  const [fontLoaded] = useFonts({
    retro: require("./assets/fonts/retro.ttf"),
  });

  const [audioLoaded, audioLoadingError] = useResolvedValue(() =>
    AudioManager.setupAsync()
  );

  const [modelsLoaded, modelLoadingError] = useResolvedValue(() =>
    ModelLoader.loadModels()
  );

  console.log("Loading:", {
    fonts: fontLoaded,
    audio: audioLoaded,
    models: modelsLoaded,
  });

  if (modelLoadingError) {
    return (
      <ErrorScreen
        message={modelLoadingError.message}
        stack={modelLoadingError.stack}
      />
    );
  }
  if (audioLoadingError) {
    return (
      <ErrorScreen
        message={audioLoadingError.message}
        stack={audioLoadingError.stack}
      />
    );
  }
  if (modelsLoaded && fontLoaded && audioLoaded && loginJwt) {
    return children;
  }

  return <EXAppLoading />;
}

const ErrorScreen = ({ message, stack }) => (
  <View style={styles.errorContainer}>
    <ScrollView style={styles.error} contentContainerStyle={{}}>
      <Text style={styles.errorTitle}>This is a fatal error 👋 </Text>
      <Text style={styles.errorText}>{message}</Text>
      {stack && (
        <Text
          style={[
            styles.errorText,
            { fontSize: 12, opacity: 0.8, marginTop: 4 },
          ]}
        >
          {stack}
        </Text>
      )}
    </ScrollView>
  </View>
);

const styles = StyleSheet.create({
  splash: {
    backgroundColor: "#87C6FF",
    resizeMode: "contain",
  },
  errorContainer: {
    ...StyleSheet.absoluteFillObject,
    flex: 1,
    backgroundColor: "black",
    justifyContent: "center",
    alignItems: "center",
  },
  error: {
    maxWidth: 300,
    maxHeight: "50%",
    borderRadius: 8,
    paddingVertical: 16,
    paddingHorizontal: 24,
    backgroundColor: "#9e0000",
  },
  errorTitle: {
    fontSize: 30,
    color: "white",
    fontWeight: "bold",
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: "rgba(0,0,0,0.1)",
  },
  errorText: {
    fontSize: 24,
    color: "white",
  },
});
