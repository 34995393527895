"use strict";
import React, { Component } from "react";
import {
  PanResponder,
  View,
  StyleSheet,
  TouchableWithoutFeedback,
} from "react-native";
import { findDOMNode } from "react-dom";

const getElement = (component) => {
  try {
    return findDOMNode(component);
  } catch (e) {
    return component;
  }
};
export const swipeDirections = {
  SWIPE_UP: "SWIPE_UP",
  SWIPE_DOWN: "SWIPE_DOWN",
  SWIPE_LEFT: "SWIPE_LEFT",
  SWIPE_RIGHT: "SWIPE_RIGHT",
};

const swipeConfig = {
  velocityThreshold: 0.3,
  directionalOffsetThreshold: 80,
};

export const keyMap = {
  Space: "SWIPE_UP",
  ArrowUp: "SWIPE_UP",
  KeyW: "SWIPE_UP",
  ArrowDown: "SWIPE_DOWN",
  KeyS: "SWIPE_DOWN",
  ArrowLeft: "SWIPE_LEFT",
  KeyA: "SWIPE_LEFT",
  ArrowRight: "SWIPE_RIGHT",
  KeyD: "SWIPE_RIGHT",
};

function isValidSwipe(
  velocity,
  velocityThreshold,
  directionalOffset,
  directionalOffsetThreshold
) {
  return (
    Math.abs(velocity) > velocityThreshold &&
    Math.abs(directionalOffset) < directionalOffsetThreshold
  );
}

const freezeBody = (e) => {
  e.preventDefault();
};
class GestureView extends Component {
  constructor(props, context) {
    super(props, context);
    this.swipeConfig = Object.assign(swipeConfig, props.config);
  }

  componentDidMount() {
    window.addEventListener("keydown", this.onKeyDown, false);
    window.addEventListener("keyup", this.onKeyUp, false);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.swipeConfig = Object.assign(swipeConfig, props.config);
  }

  onKeyDown = (e) => {
    const direction = keyMap[e.code];
    if (direction) {
      this.props.onResponderGrant();
    }
  };

  onKeyUp = (e) => {
    const direction = keyMap[e.code];
    if (direction) {
      this.props.onSwipe(direction);
    }
  };

  _handleShouldSetPanResponder = (evt, gestureState) => {
    evt.preventDefault();
    return evt.nativeEvent.touches.length === 1;
  };

  _gestureIsClick = (gestureState) => {
    return Math.abs(gestureState.dx) < 5 && Math.abs(gestureState.dy) < 5;
  };

  render() {
    const { style, ...props } = this.props;

    return (
      <View
        style={[{ flex: 1, cursor: "pointer" }, style]}
        tabIndex="0"
        ref={(view) => {
          const nextView = getElement(view);
          this.view = nextView;
        }}
        {...props}
      />
    );
  }
}

export default GestureView;
