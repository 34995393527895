import axios from "axios";
import React, { Component } from "react";
import { toast } from "react-hot-toast";
import {
  Animated,
  Dimensions,
  Easing,
  InteractionManager,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useSafeArea } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Button from "../components/Button";

import Hand from "../components/HandCTA";
import Footer from "../components/Home/Footer";
import GameContext from "../context/GameContext";
import { coinContract, gameCoinContract } from "../klaytn/contracts";
import { changeMuted } from "../src/AudioManager";
import Images from "../src/Images";

let hasShownTitle = false;

function isNumeric(value) {
  return /^\d+$/.test(value);
}


function Screen(props) {
  const loginJwt = useSelector(state => state.login.loginjwt);
  const { setCharacter, character } = React.useContext(GameContext);
  const account = useSelector(state => state.login.account);
  const animation = new Animated.Value(0);

  React.useEffect(() => {
    // function onKeyUp({ keyCode }) {
    //   // Space, up-arrow
    //   if ([32, 38].includes(keyCode)) {
    //     props.onPlay();
    //   }
    // }

    // window.addEventListener("keyup", onKeyUp, false);
    // return () => {
    //   window.removeEventListener("keyup", onKeyUp);
    // };
  }, []);

  React.useEffect(() => {
    if (!hasShownTitle) {
      hasShownTitle = true;
      InteractionManager.runAfterInteractions((_) => {
        Animated.timing(animation, {
          useNativeDriver: true,
          toValue: 1,
          duration: 800,
          delay: 0,
          easing: Easing.in(Easing.qubic),
        }).start();
      });
    }
  }, []);

  const { top, bottom, left, right } = useSafeArea();

  const animatedTitleStyle = {
    transform: [
      {
        translateX: animation.interpolate({
          inputRange: [0, 1],
          outputRange: [-Dimensions.get("window").width, 0],
        }),
      },
      {
        translateY: animation.interpolate({
          inputRange: [0, 1],
          outputRange: [-100, 0],
        }),
      },
    ],
  };

  const checkAllow = async() => {
    const allow = await coinContract.methods.allowance(account,gameCoinContract._address).call();
    if (Number(allow) === 0){
      coinContract.send({ from: account, gas: '750000', }, 'approve', gameCoinContract._address,caver.utils.toPeb(100000000000000000, 'KLAY')).then((res)=>{
        if(res.status){
          addCoin();
        }
      }).catch((error)=>{
        console.log(error);
      })
      return false;
    }
    return true;
  }

  const addCoin = async () => {
    if(await checkAllow()){
      const { value: url } = await Swal.fire({
        input: 'number',
        inputLabel: '에너지 코인 예치',
        inputPlaceholder: '추가할 수량 입력'
      })
      if (isNumeric(url)){
        gameCoinContract.send({ from: account, gas: '750000'},"stkaing",String(Number(url)*1e18)).then((res)=>{
          if(res.status){
            props.getCoin();
            Swal.fire(url + "에너지 입금 완료");
          }
        })
      }else if (url){
        Swal.fire("숫자만 입력해주세요.");
      }
    }
  }

  const withdrawCoin = async () => {
      const { value: url } = await Swal.fire({
        input: 'number',
        inputLabel: '에너지 코인 회수',
        inputPlaceholder: '회수할 수량 입력'
      })
      if (isNumeric(url)){
        const res = await axios.get('https://natusalienclub.com/coinWithdraw/',{params:{withdrawAmount:url*1e18},headers:{Authorization:`Bearer ${loginJwt}`}});
          if(res.status){
            props.getCoin();
            Swal.fire("회수 성공!");
          }
      }else if (url){
        Swal.fire("숫자만 입력해주세요.");
      }
  }

  const startGame = async() => {
    try{
    const respon = await axios.patch('https://natusalienclub.com/rank/',{},{headers:{Authorization:`Bearer ${loginJwt}`}});
    if (respon.status){
      props.getCoin()
      props.onPlay();
    }
  }catch(error){
    toast("좌측 하단 버튼을 눌러 에너지 코인을 입금해 주세요.\n1회 : 1 에너지");
  }
  }
  return (
    <View
      style={[
        styles.container,
        {
          paddingTop: top,
          paddingBottom: bottom,
          paddingLeft: left,
          paddingRight: right,
        },
      ]}
    >
         <Button
            onPress={startGame}
            imageStyle={{ alignSelf:'center',height:200,width:200 }}
            source={Images.button.long_play}
          />
        {/* <Animated.Image
          source={require("../assets/images/title.png")}
          style={[styles.title, animatedTitleStyle]}
        /> */}
        <View
          style={{
            justifyContent: "center",
            alignItems: "stretch",
            position: "absolute",
            bottom: Math.max(bottom, 8),
            left: Math.max(left, 8),
            right: Math.max(right, 8),
          }}
        >
          <Footer
            onCharacterSelect={() => {
              addCoin()
            }}
            withdrawCoin={withdrawCoin}
            onShop={() => {
              changeMuted();
            }}
            onMultiplayer={() => {
            }}
            showRanking={() => {
              props.showRanking()
            }}
            showWinner={() => {
              props.showWinner()
            }}
          />
        </View>
    </View>
  );
}

export default Screen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  title: {
    // color: 'white',
    // fontSize: 48,
    // backgroundColor: 'transparent',
    // textAlign: 'center',
    resizeMode: "contain",
    maxWidth: 600,
    width: "80%",
    height: 300,
  },
  coins: {
    fontFamily: "retro",
    position: "absolute",
    right: 8,
    color: "#f8e84d",
    fontSize: 36,
    letterSpacing: 0.9,
    backgroundColor: "transparent",
    textAlign: "right",
    shadowColor: "black",
    shadowOpacity: 1,
    shadowRadius: 0,
    shadowOffset: { width: 0, height: 0 },
  },
  paragraph: {
    margin: 24,
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    color: "#34495e",
  },
});
