import axios from "axios";
import React, { Component, useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { Alert, Animated, Easing, ScrollView, StyleSheet, Text, View } from "react-native";
import { useSafeArea } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

import Banner from "../components/GameOver/Banner";
import Footer from "../components/GameOver/Footer";
import AudioManager from "../src/AudioManager";
import Characters from "../src/Characters";
import useDimensions from "../src/hooks/useDimensions";
import Images from "../src/Images";
import state from "../src/state";

// import { setGameState } from '../src/actions/game';

//TODO: Make this dynamic
const banner = (score,nowRank) => 
{ return !nowRank ? [
  {
    color: "#36D6EB",
    title: score + "점",
  }
] 
:
[
  {
    color: "#36D6EB",
    title: score + "점",
  },
  {
    color: "#36D6EB",
    title: "랭킹 "+ String(nowRank)+"위입니다.",
  },
] 
}

// const AnimatedBanner = Animated.createAnimatedComponent(Banner);

function GameOver({ ...props }) {
  const loginJwt = useSelector(state => state.login.loginjwt);
  const {
    window: { width },
  } = useDimensions();
  const [isRanking,SetIsRanking] = useState(props.onlyRank);
  const [isWinner,SetIsWinner] = useState(props.onlyWinner);
  const [nowRank,SetNowRank] = useState(null)
  const [bannerArray,SetBannerArray] = useState([]);
  const [animations, setAnimations] = React.useState([]);

  const getRankDetail = async() => {
    const respon = await axios.get('https://natusalienclub.com/rank/'+props.score+"/",{headers:{Authorization:`Bearer ${loginJwt}`}});
    SetNowRank(respon.data);
    Swal.fire({
      title: "랭킹 "+respon.data+"위를 달성했습니다.",
      html: `<input type="text" id="nick" class="swal2-input" placeholder="닉네임">
      <input type="text" id="comment" class="swal2-input" placeholder="코멘트">`,
      confirmButtonText: '등록',
      focusConfirm: false,
      allowOutsideClick:false,
      preConfirm: () => {
        const nick = Swal.getPopup().querySelector('#nick').value
        const comment = Swal.getPopup().querySelector('#comment').value
        return { nick, comment }
      }
    }).then((result) => {
      addRank(result.value.nick,result.value.comment)
    })
  }

  const addRank = async(nick,comment) => {
    const params = {
      score:props.score,
      nickName:nick,
      comment
    }
    const respon = await axios.post('https://natusalienclub.com/rank/',params,{headers:{Authorization:`Bearer ${loginJwt}`}});
    return
  }

  const getRank = () => {
    SetBannerArray([])
    setAnimations([])
    getRankAPI()
  }

  const getRankAPI = async() => {
    const respon = await axios.get('https://natusalienclub.com/rank/',{headers:{Authorization:`Bearer ${loginJwt}`}});
    SetBannerArray(
      respon.data.map((v,index)=> {
        const nick = v.nickName || v.address
        const comment = v.comment || "";
        return {nick,comment,rank:index+1,address:v.address,score:v.score,color: "#a9a9a9"}})
    )
    setAnimations(respon.data.map((val) => new Animated.Value(0)))
  }

  const getWinnerAPI = async() => {
    const respon = await axios.get('https://natusalienclub.com/uwsgi/rankWinner/',{headers:{Authorization:`Bearer ${loginJwt}`}});
    SetBannerArray(
      respon.data.map((v,index)=> {
        const winners = v.nickName.split(',');
        const winner = winners[0] === '' ? v.rankWinner : winners[0];
        const randomWinnerAddress = v.randomWinner.split(',');
        winners.shift()
        const randomWinners = winners.map((v2,index)=> { 
          return v2 === '' ? randomWinnerAddress[index] : v2;
        })
        return {winner,randomWinners,amount:Number(v.totalAmount)/1e18,rank:index+1,color: "#a9a9a9",registerDate:v.registerDate}})
    )
    setAnimations(respon.data.map((val) => new Animated.Value(0)))
  }

  useEffect(()=>{
    _animateBanners();
  },[animations.length])

  React.useEffect(() => {
    if(!props.onlyRank && !props.onlyWinner){
      getRankDetail()
    }
    // setTimeout(() => {
    //   _animateBanners();

    //   const playBannerSound = async () => {
    //     await AudioManager.playAsync(AudioManager.sounds.banner);
    //     // const soundObject = new Audio.Sound();
    //     // try {
    //     //   await soundObject.loadAsync(AudioFiles.banner);
    //     //   await soundObject.playAsync();
    //     // } catch (error) {
    //     //   console.warn('sound error', { error });
    //     // }
    //   };
    //   playBannerSound();
    //   setTimeout(() => playBannerSound(), 300);
    //   setTimeout(() => playBannerSound(), 600);
    // }, 600);
  },[]);

  useEffect(()=>{
    if(isRanking){
      getRank();
    }else if (isWinner){
      getWinnerAPI();
    }else{
      if (props.onlyRank || props.onlyWinner){
        props.setGameState(state.Game.none);
      }else{
        const newBanner = banner(props.score,nowRank)
        SetBannerArray(newBanner);
        setAnimations(newBanner.map((val) => new Animated.Value(0)));
      }
    }
  },[isRanking,isWinner,nowRank])

  const _animateBanners = () => {
    const _animations = animations.map((animation) =>
      Animated.timing(animation, {
        useNativeDriver: true,
        toValue: 1,
        duration: 500,
        easing: Easing.elastic(),
      })
    );
    Animated.stagger(150, _animations).start();
  };

  const startGame = React.useCallback(() => {
    props.setGameState(state.Game.none);
  },[]);

  const { top, bottom, left, right } = useSafeArea();

  const imageStyle = { width: 60, height: 48 };
  const renderBanner = () => {
    if (bannerArray.length !== animations.length){
      return null
    }
    if (isRanking){
      return(
        <ScrollView>
          <View style={{width:'100%',backgroundColor:'black',paddingVertical:10}}><Text style={{alignSelf:'center',color:'white',fontSize:20,fontWeight:'bold'}}>랭킹</Text>
          </View>
      {bannerArray.map((val, index) => (
        <Banner
          animatedValue={animations[index].interpolate({
            inputRange: [0.2, 1],
            outputRange: [-width, 0],
            extrapolate: "clamp",
          })}
          style={{
            backgroundColor: val.color,
            transform: [
              {
                scaleY: animations[index].interpolate({
                  inputRange: [0, 0.2],
                  outputRange: [0, 1],
                  extrapolate: "clamp",
                }),
              },
            ],
          }}
          title={val.rank+"등 : "+val.score+"점\n"+val.nick +' "' +val.comment+'"'}
          button={val.button}
        />
      ))}
      </ScrollView>
      )
    }
    if (isWinner){
      return(
        <ScrollView>
          <View style={{width:'100%',backgroundColor:'black',paddingVertical:10}}><Text style={{alignSelf:'center',color:'white',fontSize:20,fontWeight:'bold'}}>명예의 전당</Text>
          </View>
      {bannerArray.map((val, index) => (
        <Banner
          animatedValue={animations[index].interpolate({
            inputRange: [0.2, 1],
            outputRange: [-width, 0],
            extrapolate: "clamp",
          })}
          style={{
            backgroundColor: val.color,
            transform: [
              {
                scaleY: animations[index].interpolate({
                  inputRange: [0, 0.2],
                  outputRange: [0, 1],
                  extrapolate: "clamp",
                }),
              },
            ],
          }}
          lines={9}
          title={"시즌 "+val.rank+"\n\n"+(Number(val.amount)*0.3).toFixed(1)+" 에너지\n" + val.winner+"\n\n"+(Number(val.amount)*0.1).toFixed(1)+" 에너지\n" + val.randomWinners.join('\n')}
          button={val.button}
        />
      ))}
      </ScrollView>
      )
    }
    return (<>{bannerArray.map((val, index) => (
      <Banner
        animatedValue={animations[index].interpolate({
          inputRange: [0.2, 1],
          outputRange: [-width, 0],
          extrapolate: "clamp",
        })}
        style={{
          backgroundColor: val.color,
          transform: [
            {
              scaleY: animations[index].interpolate({
                inputRange: [0, 0.2],
                outputRange: [0, 1],
                extrapolate: "clamp",
              }),
            },
          ],
        }}
        title={val.title}
        button={val.button}
      />
    ))}</>)
  }
  return (
    <View
      style={[
        styles.container,
        { paddingTop: top || 12, paddingBottom: bottom || 8 },
        props.style,
      ]}
    >
      <View key="content" style={{ flex: 1, justifyContent: "center" }}>
        {renderBanner()}
      </View>

      <Footer
        style={{ paddingLeft: left || 4, paddingRight: right || 4 }}
        getWinner={()=>{
          SetBannerArray([]);
          SetIsRanking(false);
          SetIsWinner(!isWinner)}}
        startGame={startGame}
        navigation={props.navigation}
        getRanking={()=>{
          SetBannerArray([]);
          SetIsWinner(false);
          SetIsRanking(!isRanking)}}
      />
    </View>
  );
}

export default GameOver;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  paragraph: {
    margin: 24,
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    color: "#34495e",
  },
});
